
import { getRule, RuleTypes } from "@/core/helpers/rules"
import { SelectModel } from "@/core/models/SelectModel"
import { IService } from "@/core/models/Service"
import { BranchType } from "@/core/models/StudentGroup"
import ApiService from "@/core/services/ApiService"
import { InternalRuleItem, Value } from "async-validator"

import { ElForm } from "element-plus"
import { computed, defineComponent, onMounted, ref, toRefs, watch } from "vue"
import { useRouter } from "vue-router"
import { ServiceTypeEnum, staticServiceTypeList } from "../../../../core/enums/enums"

export default defineComponent({
  name: "service-component",
  components: {},
  props: ["add", "id", "categoryParentsName", "categoryId", "businessId"],
  emits: ["closed", "getlist"],
  setup(props, { emit }) {
    const router = useRouter()
    const { add, id, categoryParentsName, categoryId, businessId } = toRefs(props)

    const serviceData = ref<IService>({} as IService)

    const branchTypeList = ref<BranchType[]>([])

    type FormInstance = InstanceType<typeof ElForm>
    const ruleFormRef = ref<FormInstance>()

    const validateStudentGroup = (
      rule: InternalRuleItem,
      value: Value,
      callback: (error?: string | Error) => void
    ) => {
      if (isEducation.value && !value)
        callback(new Error("Satış türü eğer eğitim ise grup değeri zorunludur"))
      else callback()
    }

    const rules = ref({
      name: getRule(RuleTypes.TEXT50, "Satış Türü Adı"),
      code: getRule(RuleTypes.TEXT50, "Satış Türü Kodu"),
      studentGroupId: [{ validator: validateStudentGroup, trigger: "blur" }],
    })

    const businessList = ref<SelectModel[]>([])

    const getService = async id => {
      const { data } = await ApiService.get("service/" + id)
      serviceData.value = data
    }
    async function getStudentGroup() {
      const { data } = await ApiService.get("student-group")
      branchTypeList.value = data
    }

    watch(
      () => props.add,
      newValue => {
        ruleFormRef.value?.resetFields()
        if (newValue) {
          if (!props.businessId) {
            router.push({ name: "service-list" })
          }
          serviceData.value = {
            serviceType: ServiceTypeEnum.Other,
            isActive: true,
          } as IService
        }
      }
    )

    watch(
      () => props.id,
      newValue => {
        ruleFormRef.value?.resetFields()
        if (newValue != undefined) {
          getService(newValue)
        }
      }
    )

    const isEducation = computed(() => serviceData.value.serviceType == ServiceTypeEnum.Education)

    onMounted(async () => {
      await getStudentGroup()
    })

    const showDrawer = computed(
      () => props.id != undefined || (props.add == true && props.businessId != undefined)
    )

    async function addService() {
      const data = {
        ...serviceData.value,
        serviceCategoryId: props.categoryId,
        businessId: props.businessId,
      }

      await ApiService.post("service/add", data)
      emit("getlist")
      router.push({ name: "service-list" })
    }
    async function updateService() {
      const data = { ...serviceData.value, id: props.id }
      await ApiService.post("service/edit", data)
      emit("getlist")
      router.push({ name: "service-list" })
    }

    function addOrUpdate(formEl) {
      if (!formEl) return
      formEl.validate(valid => {
        if (valid) {
          if (props.add) {
            addService()
          } else {
            updateService()
          }
        }
      })
    }

    function onServiceTypeChange() {
      if (!isEducation.value) serviceData.value.studentGroupId = undefined
    }

    return {
      newService: add,
      // eslint-disable-next-line vue/no-dupe-keys
      categoryParentsName,
      // eslint-disable-next-line vue/no-dupe-keys
      categoryId,
      // eslint-disable-next-line vue/no-dupe-keys
      id,
      // eslint-disable-next-line vue/no-dupe-keys
      businessId,
      showDrawer,
      serviceData,
      businessList,
      addOrUpdate,
      ruleFormRef,
      rules,
      branchTypeList,
      staticServiceTypeList,
      onServiceTypeChange,
      isEducation,
    }
  },
})
