
import ApiService from "@/core/services/ApiService"

import { useRoute, useRouter } from "vue-router"
import { defineComponent, onMounted, ref, watch } from "vue"
import ServiceComponent from "@/components/education/definitions/service/service.vue"

import { CirclePlusFilled, Plus, Edit, Remove } from "@element-plus/icons-vue"
import { TooltipInstance } from "element-plus"
import { SelectModel } from "@/core/models/SelectModel"
import { IService } from "@/core/models/Service"
import swalConfirm from "@/core/helpers/swal-confirm"
import { AxiosRequestConfig } from "axios"
import { ServiceHierarchy } from "@/core/models/ServiceHierarchy"
import { staticServiceTypeList } from "../../../../core/enums/enums"

export default defineComponent({
  name: "service-list",
  components: { ServiceComponent },
  props: ["add"],
  setup(props) {
    const route = useRoute()
    const router = useRouter()
    const serviceList = ref<IService[]>([])
    const categoryList = ref<ServiceHierarchy[]>([])
    const isCategoryLoading = ref(false)
    const isServiceTableLoading = ref(false)
    const newService = ref(false)
    const activeCategoryId = ref<string | undefined>()
    const activeCategoryName = ref<string | undefined>()
    const categoryParentsName = ref<string | undefined>()
    const activeServiceId = ref<string | undefined>()
    const activeBusinessId = ref<string>()
    const businessList = ref<SelectModel[]>([])
    const isBusinessSelectLoading = ref(false)

    const popoverAddRef = ref<TooltipInstance>()
    const popoverEditRef = ref<TooltipInstance>()

    const categoryProps = {
      value: "id",
      label: "name",
      children: "childs",
    }

    watch(
      () => route.params.service_id,
      newValue => {
        activeServiceId.value = newValue as string
      }
    )

    watch(
      () => props.add,
      newValue => {
        if (newValue && activeBusinessId.value == undefined) {
          routeToList()
        }
        newService.value = true == newValue
      }
    )

    async function getServiceList(categoryId) {
      isServiceTableLoading.value = true
      if (!categoryId && activeBusinessId.value != undefined) {
        const config = {
          params: {
            onlyRoot: true,
          },
        }
        const { data } = await ApiService.query(
          `service/business/${activeBusinessId.value}`,
          config
        )
        isServiceTableLoading.value = false
        serviceList.value = data
      } else if (categoryId != undefined) {
        const { data } = await ApiService.get(`service/category/${categoryId}`)
        isServiceTableLoading.value = false
        serviceList.value = data
      }
    }

    function getAllParents(data, out = "") {
      out = data.label + (out == "" ? "" : " ") + out
      if (data.parent) out = getAllParents(data.parent, out)
      return out
    }

    async function getCategoryList(activeBusinessId) {
      if (!activeBusinessId) {
        categoryList.value = []
        return
      }
      const query = {
        isWithService: false,
      } as AxiosRequestConfig
      const { data } = await ApiService.query(
        `service-category/business/${activeBusinessId}/childs`,
        query
      )
      isCategoryLoading.value = true
      categoryList.value = [
        {
          name: "Ana Kategori",
          isService: false,
          childs: [],
        },
        ...data,
      ]
    }

    const getBusinessList = async () => {
      isBusinessSelectLoading.value = true
      const { data } = await ApiService.get("business")
      businessList.value = data.filter(x => x.isActive).map(x => ({ name: x.title, id: x.id }))
      if (businessList.value.length == 1) {
        activeBusinessId.value = businessList.value[0].id
        getCategoryList(activeBusinessId.value)
      }
      isBusinessSelectLoading.value = false
    }

    const routeToList = () => {
      router.push({ name: "service-list" })
    }

    const removeService = async serviceId => {
      const confirmResult = await swalConfirm()
      if (confirmResult.isConfirmed) {
        await ApiService.get("service/remove/" + serviceId)
        await getServiceList(activeCategoryId.value)
      }
    }

    onMounted(() => {
      activeServiceId.value = route.params.service_id as string
      newService.value = props.add

      if (newService.value && activeBusinessId.value == undefined) {
        routeToList()
      }
      getBusinessList()
    })

    const onCategoryNodeClick = data => {
      getServiceList(data.id)
    }

    const removeCategory = async (e, id) => {
      e.preventDefault()
      await ApiService.get("service-category/remove/" + id)
      getCategoryList(activeBusinessId.value)
    }

    const addCategory = async (e, parentId) => {
      if (!activeBusinessId.value) return
      e.stopPropagation()
      const data = {
        businessId: activeBusinessId.value,
        name: activeCategoryName.value,
      }
      if (parentId) data["parentId"] = parentId
      await ApiService.post("service-category/add", data)
      resetCategoryName()
      getCategoryList(activeBusinessId.value)
    }
    const addService = (e, node) => {
      e.stopPropagation()
      activeCategoryId.value = node.data.id
      categoryParentsName.value = node.data.id != undefined ? getAllParents(node, "") : undefined
      router.push({ name: "service-add" })
    }

    const getCategory = (e, data) => {
      e.stopPropagation()
      if (data) {
        activeCategoryName.value = data.name
        activeCategoryId.value = data.id
      }
    }

    const editCategory = async ($event, category, node) => {
      const data = {
        id: category.id,
        businessId: activeBusinessId.value,
        parentId: node.parent?.data.id,
        name: activeCategoryName.value,
      }
      await ApiService.post("service-category/edit", data)
      getCategoryList(activeBusinessId.value)
    }

    const resetCategoryName = () => {
      activeCategoryName.value = ""
    }

    return {
      serviceList,
      categoryList,
      Plus,
      Edit,
      Remove,
      CirclePlusFilled,
      isServiceTableLoading,
      isCategoryLoading,
      getServiceList,
      getCategoryList,
      newService,
      activeServiceId,
      removeService,
      routeToList,
      businessList,
      activeBusinessId,
      activeCategoryId,
      activeCategoryName,
      categoryParentsName,
      isBusinessSelectLoading,
      categoryProps,
      onCategoryNodeClick,
      addCategory,
      addService,
      removeCategory,
      editCategory,
      getCategory,
      popoverAddRef,
      popoverEditRef,
      staticServiceTypeList,
    }
  },
})
